<template>
  <h2>公告管理</h2>
  <form action="" @submit.prevent="insertAnnouncement()">
    <div class="card mb-5">
      <div class="card-body">
        <div class="mb-3">
          <label class="form-label">公告內容</label>
          <input
            type="text"
            class="form-control"
            required
            :disabled="isLockWhileSaveAnnouncmentFlag"
            v-model="announcement.message"
          />
        </div>

        <div class="row justify-content-start">
          <div class="col-4">
            <div class="mb-3">
              <label class="form-label">公告起始時間</label>
              <div class="input-group mb-3">
                <input
                  type="date"
                  size="1"
                  required
                  :disabled="isLockWhileSaveAnnouncmentFlag"
                  class="form-control"
                  v-model="startDatetime.date"
                />
                <span>
                  <input
                    type="time"
                    required
                    :disabled="isLockWhileSaveAnnouncmentFlag"
                    class="form-control"
                    v-model="startDatetime.time"
                  />
                </span>
              </div>
            </div>
          </div>
          <div class="col-4">
            <div class="mb-3">
              <label class="form-label">公告結束時間</label>
              <div class="input-group">
                <input
                  type="date"
                  required
                  :disabled="isLockWhileSaveAnnouncmentFlag"
                  class="form-control"
                  v-model="endDatetime.date"
                />
                <span>
                  <input
                    type="time"
                    required
                    :disabled="isLockWhileSaveAnnouncmentFlag"
                    class="form-control"
                    v-model="endDatetime.time"
                  />
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="row justify-content-start">
          <div class="col text-center">
            <button
              type="submit"
              :disabled="isLockWhileSaveAnnouncmentFlag"
              class="btn btn-info save-button"
            >
              儲存
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import Swal from "sweetalert2";
import axios from "axios";
export default {
  data() {
    return {
      now: new Date(),
      announcement: {
        message: "",
        date: "",
        startDatetime: "",
        endDatetime: "",
      },
      startDatetime: {
        date: new Date().toISOString().slice(0, 10),
        time: "00:00",
      },
      endDatetime: {
        date: new Date(new Date().getTime() + 86400000)
          .toISOString()
          .slice(0, 10),
        time: "00:00",
      },
      isLockWhileSaveAnnouncmentFlag: 0,
    };
  },
  methods: {
    //  警告視窗
    async triggerSweetAlert(icon, message, type) {
      try {
        let swal;
        switch (type) {
          case "confirm":
            swal = {
              cancelButtonText: "返回上一步",
              showCancelButton: true,
              icon: icon,
              text: message,
              confirmButtonColor: "#1c8554",
              confirmButtonText: "確認發送",
            };
            break;

          case "info":
            swal = {
              icon: icon,
              text: message,
              confirmButtonText: "確認",
            };
            break;
          case "timer":
            swal = {
              icon: icon,
              title: message,
              showConfirmButton: false,
              timer: 1500,
            };
            break;

          default:
            swal = {
              icon: icon,
              text: message,
              confirmButtonText: "確認",
            };
            break;
        }

        const result = await new Swal(swal);
        return result;
      } catch (error) {
        console.error(error);
      }
    },

    // 儲存公告
    async pushAnnouncement(message, startDateTime, endDateTime) {
      try {
        const announcementData = await axios.post(
          "https://us-central1-gx-economic-adviser.cloudfunctions.net/announcement",
          {
            message: message,
            startDateTime: startDateTime,
            endDateTime: endDateTime,
          }
        );
        return announcementData;
      } catch (error) {
        console.log(error);
      }
    },

    // 儲存推播
    async insertAnnouncement() {
      let confirmInsertAnnnocement = await this.triggerSweetAlert(
        "warning",
        "是否確認此公告訊息",
        "confirm"
      );
      if (confirmInsertAnnnocement.isConfirmed === true) {
        this.isLockWhileSaveAnnouncmentFlag = 1;
        let message = this.announcement.message;
        let startDateTime = this.concatDatetimeString(
          this.startDatetime.date,
          this.startDatetime.time
        );
        let endDateTime = this.concatDatetimeString(
          this.endDatetime.date,
          this.endDatetime.time
        );

        let pushAnnouncementResult = await this.pushAnnouncement(
          message,
          startDateTime,
          endDateTime
        );

        this.isLockWhileSaveAnnouncmentFlag = 0;
        if (pushAnnouncementResult.status === 200) {
          this.triggerSweetAlert("success", "儲存公告成功", "timer");
        } else {
          console.log(pushAnnouncementResult);
          this.triggerSweetAlert("error", "儲存公告失敗：意外錯誤", "timer");
        }
      }

      // 重置表格
      this.announcement = {
        id: "",
        message: "",
        date: "",
        startDatetime: "",
        endDatetime: "",
      };

      this.startDatetime = {
        date: new Date().toISOString().slice(0, 10),
        time: "00:00",
      };

      this.endDatetime = {
        date: new Date(new Date().getTime() + 86400000)
          .toISOString()
          .slice(0, 10),
        time: "00:00",
      };
    },

    // 計算推播日期
    concatDatetimeString(date, time) {
      let dateTimeString = "";

      let hourString = time.substring(0, 5);
      let timeString = hourString + ":00";
      dateTimeString = date + " " + timeString;

      return dateTimeString;
    },
  },
  computed: {},
  async created() {},
  updated() {},
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style>

<style scoped>
.save-button {
  color: white;
}
</style>
